body {
  background: #131922 !important;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  font-family: Jura !important;
}

body p {
  font-family: Jura !important;
}


@font-face {
  font-family: Jura;
  src: url("./assets/font/Jura.ttf");
}

@font-face {
  font-family: JosefinSans;
  src: url("./assets/font/JosefinSans.ttf");
}

@font-face {
  font-family: Lato;
  src: url("./assets/font/Lato-Regular.ttf");
}


.App {
  font-family: Jura !important;
}

.Stepper svg, 
.Stepper text {
  color: white !important;
  fill: white !important;
  font-family: Jura !important;
}

.MuiStepConnector-root {
  display: none !important;
  font-family: Jura !important;
}

.SwapPage .am5-layer-30 {
  display: none !important;
  font-family: Jura !important;
}

canvas.am5-layer-30 {
  display: none;
}